.header {
  width: 100%;
  /* position: sticky;
  z-index: 1;
  top: 0; */
  background-color: white;
  box-shadow: var(--shadow-elevation-low);
}

.header address {
  font-style: normal;
  text-align: right;
  margin: 0 10px;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav .iconButton {
  color: black;
}

.nav ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  flex: 1;
}

.nav li,
.mobileNav li {
  margin: 10px 20px;
  font-size: 1.5em;
}

.nav .logo {
  text-decoration: none;
  text-align: center;
  display: inline-block;
  margin: 10px;
}
.nav a.logo:hover {
  text-decoration: none;
}

.header[data-mobile] {
  padding: 8px 16px;
}

.mobileNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobileNav address {
  text-align: center;
  line-height: 2em;
}

@media screen and (max-width: 1024px) {
  .nav li {
    margin: 10px 20px;
    font-size: 1em;
  }
}

@media screen and (max-width: 820px) {
  .nav li {
    margin: 10px 20px;
    font-size: 0.75em;
  }
}

@media screen and (max-width: 700px) {
  .nav li {
    margin: 10px 20px;
    font-size: 0.5em;
  }
}
