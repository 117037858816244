body {
  --shadow-color: 286deg 36% 56%;
  --shadow-elevation-low:
    0.3px 0.6px 0.8px hsl(var(--shadow-color) / 0.34),
    0.5px 0.9px 1.2px -1.2px hsl(var(--shadow-color) / 0.34),
    1.3px 2.2px 2.9px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium:
    0.3px 0.6px 0.8px hsl(var(--shadow-color) / 0.36),
    1.1px 1.8px 2.4px -0.8px hsl(var(--shadow-color) / 0.36),
    2.7px 4.6px 6px -1.7px hsl(var(--shadow-color) / 0.36),
    6.6px 11.2px 14.6px -2.5px hsl(var(--shadow-color) / 0.36);
  --shadow-elevation-high:
    0.3px 0.6px 0.8px hsl(var(--shadow-color) / 0.34),
    1.9px 3.3px 4.3px -0.4px hsl(var(--shadow-color) / 0.34),
    3.6px 6.1px 8px -0.7px hsl(var(--shadow-color) / 0.34),
    5.9px 10px 13.1px -1.1px hsl(var(--shadow-color) / 0.34),
    9.4px 16px 20.9px -1.4px hsl(var(--shadow-color) / 0.34),
    14.7px 25px 32.6px -1.8px hsl(var(--shadow-color) / 0.34),
    22.4px 38px 49.6px -2.1px hsl(var(--shadow-color) / 0.34),
    32.9px 55.9px 73px -2.5px hsl(var(--shadow-color) / 0.34);

  margin: 0;
  padding: 0;

  font-family: 'Montserrat', 'Roboto', 'Oxygen', 'Helvetica Neue', sans-serif;
  width: 100%;
}

.header a,
a.hidden {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.header a:hover,
a.hidden:hover {
  text-decoration: underline;
}

a.noStyles {
  text-decoration: none;
  color: inherit;
}

a[href^="http"] {
  text-decoration: underline;
  color: #747474;
}

main {
  width: 100%;
}

.no-breaks {
  white-space: nowrap;
}

.container {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.white {
  color: white;
}

.centered {
  text-align: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton {
  border: none;
  background: none;
  padding: 8px;
}

.block {
  display: block;
}

.divider {
  height: 1px;
  margin-top: -1px;
  width: 100%;
  background-color: #e0e0e0;
}

.backdrop {
  backdrop-filter: blur(50px);
}

.layout.right {
  padding: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 50%;
  margin-left: 50%;
}
.layout.left {
  padding: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 50%;
}
.layout.middle {
  width: calc(100% - 50px);
  max-width: 700px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
}
.layout.middle.fitted {
  width: 100%;
  max-width: min-content;
}
.layout.band {
  padding: 50px;
}
.max-width {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.h0 {
  font-size: 60px;
  margin-left: -4px;
}
h1, .h1 {
  font-size: 40px;
  margin-left: -3px;
}
h2, .h2 {
  font-size: 30px;
  margin-left: -2px;
}
h3, .h3 {
  font-size: 20px;
  font-weight: bold;
  margin-left: 0px;
}
p, blockquote {
  font-size: 18px;
}
p.p2 {
  font-size: 16px;
}

blockquote {
  font-style: italic;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-left: 5px solid #e0e0e0;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 100vh;
  background-color: white;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  padding: 8px 16px;
}

.overlay.open {
  width: 100%;
  opacity: 2;
  pointer-events: initial;
  position: fixed;
}


input[type=text],
input[type=password],
input[type=email],
input[type=number],
input[type=date],
input[type=tel],
input[type=url],
input[type=search],
textarea {
  border: 1px solid #747474;
  border-radius: 4px;
  font-family: inherit;
  width: 100%;
  font-size: 16px;
  padding: 5px 16px;
}

button,
.button {
  text-transform: uppercase;
  font-family: inherit;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  padding: 11px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  filter: saturate(0.5);
  cursor: default;
}

textarea {
  resize: vertical;
}

button.fullWidth,
.button.fullWidth {
  width: 100%;
}
button.halfWidth,
.button.halfWidth {
  width: 50%;
  min-width: fit-content;
}

button.high,
.button.high {
  background: #0075FF;
  color: white;
}

button.low,
.button.low {
  background: white;
  color: #0075FF;
}

button:disabled
.button.disabled {
  pointer-events: none;
  background-color: #5d5e5f;
}

.card-width {
  width: 100% !important;
  max-width: 500px !important;
  margin: 0 auto;
}
.card {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 40px 80px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.cardFlow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.cardFlow .card {
  width: calc(33% - 40px);
  padding: 20px 30px;
  margin: 12px;
}

.card .footer {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center
}

@media screen and (max-width: 1000px) {
  .layout.right {
    width: 75%;
    margin-left: 25%;
  }
  .layout.left {
    width: 75%;
  }
  .cardFlow .card {
    width: calc(50% - 40px);
  }
}

@media screen and (max-width: 745px) {
  .cardFlow .card {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .layout.right,
  .layout.left {
    width: calc(100% - 50px);
    margin: 20vh auto 0;
    border-radius: 10px;
  }

  .layout.band {
    padding: 10px;
  }

  .h0 {
    font-size: 55px;
  }
  h1, .h1 {
    font-size: 50px;
  }
  h2, .h2 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }

  .card {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 350px) {
  .h0 {
    font-size: 45px;
  }
}

/* ----------------------------- Third party ------------------------------------ */

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}
 
@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}